import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Rating from "@mui/material/Rating";

const GoogleCustomer = [
  {
    name: "Hilary LeBlanc",
    description:
      "My engagement ring is super special because it was the hand-me-down ring of my husbands late grandmother. One day the stone fell out and I freaked out! I went to Brillante Jewelers because so many people recommended them and I was not disappointed! They were able to take my stone, set it in a beautiful way and they addressed my concerns beautifully. Now I have my ring back and it’s even more gorgeous than before. They were all so great to work with, kind, and professional! They earned every bit of the 5 stars (I would have given more if I could!) I’ll be back to get my ring engraved and I would recommend anyone who needs jewelry or needs to perfect their current jewelry, go HERE!!!",
    googleUrl:
      "https://www.google.com/search?q=brillante+jewelers&source=hp&ei=YL8OZNGdFaOL4-EPo#lrd=0x88d8d8acf775940b:0x4bf445f5b8085398,1,,,,",
    rating: "5",
  },
  {
    name: "Bobette McCurdy",
    description:
      "This is one of the best Jewelers I have found. They are polite, gracious, and bend over backward to make their customers happy. They can design or remake any piece of Jewlery you might need and it comes out exquisitely.I would recommend them to anyone that wants professional work done with the best quality you can find.",
    googleUrl:
      "https://www.google.com/search?q=brillante+jewelers&source=hp&ei=YL8OZNGdFaOL4-EPo#lrd=0x88d8d8acf775940b:0x4bf445f5b8085398,1,,,,",
    rating: "5",
  },
  {
    name: "Chase Luis Foster",
    description:
      "Brillante Jewelers offers such an incredible customer experience! Everyone was extremely kind and the atmosphere of the store was luxurious yet welcoming. I had sizing holes added two leather watch bands and they fit me perfectly now. Thank you Brillante!",
    googleUrl:
      "https://www.google.com/search?q=brillante+jewelers&source=hp&ei=YL8OZNGdFaOL4-EPo#lrd=0x88d8d8acf775940b:0x4bf445f5b8085398,1,,,,",
    rating: "5",
  },
  {
    name: "Zak Jordan",
    description:
      "Today was my first visit to Brillante and we will definitely be going back.  Staff was extremely friendly, and did a fantastic job cleaning my wife's wedding set.  I know my camera and lighting aren't fantastic, but trust me, the rings are all shine.  Really happy we found this family ran establishment, great folks!",
    googleUrl:
      "https://www.google.com/search?q=brillante+jewelers&source=hp&ei=YL8OZNGdFaOL4-EPo#lrd=0x88d8d8acf775940b:0x4bf445f5b8085398,1,,,,",
    rating: "5",
  },
  {
    name: "Kathy Tufts",
    description:
      "Brillante never disappoints. They are professional and efficient each time I have asked them to repair a piece of jewelry or design a new piece. Friendly and cost effective. No need to go anywhere else!",
    googleUrl:
      "https://www.google.com/search?q=brillante+jewelers&source=hp&ei=YL8OZNGdFaOL4-EPo#lrd=0x88d8d8acf775940b:0x4bf445f5b8085398,1,,,,",
    rating: "5",
  },
];

const YelpCustomer = [
  {
    name: "Vittoria M.",
    description:
      "I went here to try to get my ring resized and I had a great experience! He was so nice and honest. He even told me to try to call the company we got it from to see if they would do it for free. I won't go anywhere else for my Jewelry needs!",
    googleUrl: "https://www.yelp.com/biz/brillante-jewelers-boynton-beach",
    rating: "5",
  },
  {
    name: "Zak J.",
    description:
      "First time stopped by Brillante, and will definitely be back.  The guys running the shop were super friendly and very professional.  I had my wife's wedding bands cleaned and they came back 100% shine, looking brand new.  Quick job and I was back on my way.  Love their inventory, will definitely be visiting soon with my wife!    ",
    googleUrl: "https://www.yelp.com/biz/brillante-jewelers-boynton-beach",
    rating: "5",
  },
  {
    name: "Brittany W.",
    description:
      "What an amazing group of professionals! I recently had my engagement resized from a 7 to a 6, however after taking the ring home and wearing it around I found that it was still slightly big (100% my fault for not going smaller). I called Brilliante jewelers and they told me to bring it back the next day and they would take care of it free of charge. Incredibly genuine, nice staff and there work is absolutely beautiful. Highly recommended!    ",
    googleUrl: "https://www.yelp.com/biz/brillante-jewelers-boynton-beach",
    rating: "5",
  },
  {
    name: "Judi P.",
    description:
      "Brought in my old jewelry and assisted with designing a new wedding ring. They were fantastic and extremely helpful.",
    googleUrl: "https://www.yelp.com/biz/brillante-jewelers-boynton-beach",
    rating: "5",
  },
  {
    name: "Kathy Tufts.",
    description:
      "I was visiting my mother in Florida and I had just picked up a ring for my anniversary the day before we left. It had been sized but it was still a little too big. I went to Brillante and was helped by the most helpful and knowledgeable gentleman who put in a ring sizer for me. Two days later I went back so they could make it smaller again and the first man was there but another wonderful gentleman helped me. I was sorry I didn't have any jewelry to buy because this would absolutely be my first choice of where to go. Their selection was beautiful and their service was impeccable. I would definitely recommend for any service. :)    ",
    googleUrl: "https://www.yelp.com/biz/brillante-jewelers-boynton-beach",
    rating: "5",
  },
];


function WhatPeopleSay() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    arrow: true,
    centerMode: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    centerPadding: "20px",
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <BsChevronLeft />,
    nextArrow: <BsChevronRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="Lt_testimonials_section">
        <div className="container">
          <div className="Lt_title_text">
            <h2>What People Say</h2>
          </div>

          <div className="slider-wrapper">
            <Tabs
              defaultActiveKey="google"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="google" title="">
                <div className="Lt_people_content_main">
                  <Slider {...settings}>
                    {GoogleCustomer.map((value) => (
                      <div className="Lt_people_item">
                        <p className="desc ">
                          {value?.description?.slice(0, 200)}
                          {value?.description?.length > 200 ? "..." : ""}

                          <Link
                            to="#"
                            onClick={() =>
                              window.open(value.googleUrl, "_blank")
                            }
                            target="_blank"
                            className="review_read_more"
                          >
                            Read more...
                          </Link>
                          <div className="Lt_peope_rating">
                            <Rating
                              name="read-only"
                              value={value.rating}
                              readOnly
                            />
                          </div>
                        </p>

                        <h3 className="title18">
                          <Link
                            target="_blank"
                            onClick={() =>
                              window.open(value.googleUrl, "_blank")
                            }
                            to="#"
                            className="txt-black"
                          >
                            {value.name}
                          </Link>
                        </h3>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Tab>
              <Tab eventKey="yelp" title="">
                <div className="Lt_people_content_main">
                <Slider {...settings}>
                  {YelpCustomer.map((value) => (
                    <div className="Lt_people_item">
                      <p className="desc ">
                        {value?.description?.slice(0, 200)}
                        {value?.description?.length > 200 ? "..." : ""}

                        <Link
                          to="#"
                          onClick={() => window.open(value.googleUrl, "_blank")}
                          target="_blank"
                          className="review_read_more"
                        >
                          Read more...
                        </Link>
                        <div className="Lt_peope_rating">
                          <Rating
                            name="read-only"
                            value={value.rating}
                            readOnly
                          />
                        </div>
                      </p>

                      <h3 className="title18">
                        <Link
                          target="_blank"
                          onClick={() => window.open(value.googleUrl, "_blank")}
                          to="#"
                          className="txt-black"
                        >
                          {value.name}
                        </Link>
                      </h3>
                    </div>
                  ))}
                  </Slider>

                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatPeopleSay;
