import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/LondonHome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SideStone from "../assets/images/home/ring-style/sidestone.png";
import PaveImg from "../assets/images/home/ring-style/pave.png";
import BezelImg from "../assets/images/home/ring-style/bezel.png";
import EternityImg from "../assets/images/home/ring-style/eternity.png";
import CurvedImg from "../assets/images/home/ring-style/curved.png";
import AnniversaryImg from "../assets/images/home/ring-style/aniversary.png";
import HaloImg from "../assets/images/home/ring-style/halo.png";
import SolitaireImg from "../assets/images/home/ring-style/solitaire.png";
import VintageImg from "../assets/images/home/ring-style/vintage.png";
import ChannelImg from "../assets/images/home/ring-style/channel-set.png";
import StackableImg from "../assets/images/home/ring-style/stackable.png";

const rings = [
  {
    title: "Side-Stone Rings",
    imgUrl: SideStone,
    Path: "/ringsettings",
  },
  {
    title: "Pave Rings",
    imgUrl: PaveImg,
    Path: "/ringsettings",
  },
  {
    title: "Bezel Rings",
    imgUrl: BezelImg,
    Path: "/ringsettings",
  },
  {
    title: "Eternity Rings",
    imgUrl: EternityImg,
    Path: "/ringsettings",
  },
  {
    title: "Curved Rings",
    imgUrl: CurvedImg,
    Path: "/ringsettings",
  },
  {
    title: "Anniversary Rings",
    imgUrl: AnniversaryImg,
    Path: "/ringsettings",
  },
  {
    title: "Halo Rings",
    imgUrl: HaloImg,
    Path: "/ringsettings",
  },
  {
    title: "Solitaire Rings",
    imgUrl: SolitaireImg,
    Path: "/ringsettings",
  },
  {
    title: "Vintage Rings",
    imgUrl: VintageImg,
    Path: "/ringsettings",
  },
  {
    title: "Channel Set Rings",
    imgUrl: ChannelImg,
    Path: "/ringsettings",
  },
  {
    title: "Stackable Rings",
    imgUrl: StackableImg,
    Path: "/ringsettings",
  },
];

function BestSeller() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    arrow: true,
    centerMode: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
            centerPadding: "30px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="Lt_bestSeller_section">
        <Container fluid>
          <Row className="m-0 w-100">
            <Col>
              <div className="Lt_title_text">
                <h2> Meet Our Best Sellers </h2>
              </div>
            </Col>

            <Col>
              <div className="Lt_bestSeller_slider">
                <Slider {...settings}>
                  {rings.map((data) => (
                    <div className="Lt_BestSeller">
                      <img src={data.imgUrl} />
                      <Link to={data.Path}>{data.title} </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BestSeller;
