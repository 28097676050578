import React from "react";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import ServingImg from "../assets/images/home/serving_text.png";
import LeftBg from "../assets/images/home/left-bg.png";
import RightBg from "../assets/images/home/right-bg.png";
import yearsImg from "../assets/images/home/35_years.png";
import yearsImgMobile from "../assets/images/home/35_years_mobile.png";

function Serving() {
  return (
    <>
      <section className="Lt_35years_section">
        <div className="bg_left">
          <img src={LeftBg} />
        </div>

        <div className="Lt_35years_main">
          <div className="Lt_35years_img">
            <img src={yearsImg} className="d-none d-lg-block d-md-block d-xl-block"/>
            <img src={yearsImgMobile} className="d-block d-lg-none d-md-none d-xl-none m-auto" />

          </div>
          <div className="Lt_35years_text">
            <h2>Serving The Boynton Beach Area</h2>
          </div>
        </div>

        <div className="bg_right">
          <img src={RightBg} />
        </div>
      </section>
    </>
  );
}

export default Serving;
