import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/LondonHome.css";
import { Link } from "react-router-dom";
import BrillanteStore from '../../../../Assets/images/brillante/brillante_store.webp';
import Diamond from "../assets/images/home/h-diamond.png";
import Shape from "../assets/images/home/h-pattern-1.svg";

function LearnDiamond() {
  return (
    <>
      <section className="Lt_LearnDiamond_section">
        <Container >
          <Row className="m-0 w-100">
            <Col lg={6}>
              <div className="am1_box_mobile">
                <div className="zoom-image">
                  <img
                    src={Diamond}
                    className="img-responsive"
                    alt="Diamonds"
                  />
                  <div className="position-btn btn-d1">
                    <Link to="#">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="contentsec">
                  <img
                    src={Shape}
                    alt="Pattern"
                  />
                  <h2>Learn About Diamonds</h2>
                  <p>
                    Until the middle of the twentieth century, there was no
                    agreed-upon standard by which diamonds could be judged.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="am1_box_mobile">
                <div className="zoom-image">
                  <img
                    src={BrillanteStore}
                    className="img-responsive"
                    alt="Our Showroom"
                  />
                  <div className="position-btn">
                    <Link to="/our-story">Learn More</Link>
                  </div>
                </div>
                <div className="contentsec">
                  <img
                    src={Shape}
                    alt="Pattern"
                  />
                  <h2>Our Showroom</h2>
                  <p>
                    Visit one of our showrooms, and talk to a friendly diamond
                    expert to find out how we can best cater to your budget and
                    design requirements.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default LearnDiamond;
