import React, { useEffect } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRightShort } from 'react-icons/bs';
import ENGAGEMENT from "../assets/images/home/eng-ring.jpg"
import WEDDING from "../assets/images/home/about-2.png"

const EngWedRing = () => {
  const history = useHistory();

  const banner = localStorage.getItem("bw-bannerdata")
    ? JSON.parse(localStorage.getItem("bw-bannerdata"))
    : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;
  return (
    <>
    <section className="Lt_EngWed_section">
        <Container>
            <Row className="align-items-center">
                <Col lg={6}>
                    <div className="Lt_Eng_img">
                        <img  src={ENGAGEMENT} />
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="Lt_Eng_text">
                        <h3>ENGAGEMENT RINGS</h3>
                        <h2>Uniquely crafted just for you</h2>
                        <p>
                        The pinicale expression of love, Culet Diamonds Custom-made engagement rings featuring the rarest D-F colour, eye clean diamonds GIA certified conflict free. Made to the highest level of detail by our team of experience Jewellers and Diamond setters.
                        </p>
                        <Button className="Lt_fill_btn" onClick={(e) => { window.location.href = `/ringsettings` }} >
                            Shop Now
                        </Button>
                    </div>
                </Col>
            </Row>


            <Row className="align-items-center mt-lg-5">
                <Col lg={6} className="Lt_order_2">
                    <div className="Lt_Eng_text">
                        <h3>WEDDING RINGS</h3>
                        <h2>Together forever</h2>
                        <p>
                        View our gorgeous collection of wedding rings, purposefully designed to enhance the overall beauty of your engagement ring and symbolise the love you share for one another.
                        </p>
                        <Button className="Lt_fill_btn" onClick={(e) => { window.location.href = `/jewelry/wedding-rings` }} >
                            Shop Now
                        </Button>
                    </div>
                </Col>
                <Col lg={6} className="">
                    <div className="Lt_Eng_img">
                        <img  src={WEDDING} />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  );
};

export default EngWedRing;
