import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/LondonHome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Custom from "../assets/images/home/ring-style/custom.png";
import Watchmaking from "../assets/images/home/ring-style/watchmaking.png";
import Platinum from "../assets/images/home/ring-style/jewellery.png";
import Watches from "../assets/images/home/ring-style/wristwatch.png";
import Appraisal from "../assets/images/home/ring-style/evaluation.png";
import Buyers from "../assets/images/home/ring-style/buyer.png";

const service = [
  {
    title: "CUSTOM DESIGN",
    ImgIcon: Custom,
    para: "We specialize in custom design. We use advanced CAD design software to precisely create that beautiful piece of jewelry you crave.",
    BtnUrl: "/our-story",
  },
  {
    title: "CERTIFIED WATCHMAKER",
    ImgIcon: Watchmaking,
    para: "Watch repairs are done to name brand specifications with original parts by a certified watchmaker and we provide a 1 year warranty on all repairs.",
    BtnUrl: "/our-story",
  },
  {
    title: "PLATINUM & STERLING GOLD",
    ImgIcon: Platinum,
    para: "We carry an extensive line of exquisite and unique jewelry in 14k gold, 18k gold, platinum and sterling silver.",
    BtnUrl: "/our-story",
  },
  {
    title: "JEWELRY & WATCHES",
    ImgIcon: Watches,
    para: "We expertly repair and restore your jewelry and watches.",
    BtnUrl: "/our-story",
  },
  {
    title: "APPRAISALS",
    ImgIcon: Appraisal,
    para: "We appraise jewelry for insurance and estate purposes.",
    BtnUrl: "/our-story",
  },
  {
    title: "BUYERS",
    ImgIcon: Buyers,
    para: "We buy gold, silver, platinum, diamonds and high end watches.",
    BtnUrl: "/our-story",
  },
];

function WhyBuy() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    arrow: true,
    centerMode: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "10px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="Lt_whyBuy_section d-none d-lg-block d-xl-block">
        <Container className="rcs_custome_container">
          <Row className="m-0 w-100">
            <Col lg={12} className="mb-5">
              <div className="Lt_title_text">
                <h2> Explore The Brilliance of Brillante's Services </h2>
              </div>
            </Col>

            {service.map((data) => (
              <Col lg={4}>
                <div className="am_box">
                  <img src={data.ImgIcon} />
                  <h6 className="tt-title">{data.title}</h6>
                  {/* <p>{data.para}</p> */}
                  {data?.para?.slice(0, 70)}
                  {data?.para?.length > 70 ? "..." : ""}
                </div>
              </Col>
            ))}

            <Col>
              <div class="text-center  mt-2">
                <Link to="/our-story" class="abt-heading">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="Lt_whyBuy_section d-lg-none d-xl-none">
        <Container className="rcs_custome_container">
          <Row className="m-0 w-100">
            <Col lg={12} className="mb-5">
              <div className="Lt_title_text">
                <h2> Explore The Brilliance of Brillante's Services </h2>
              </div>
            </Col>

            <Col className="Lt_bestSeller_slider">
              <Slider {...settings}>
                {service.map((data) => (
                  <div className="bri_service_box_mob">
                    <div className="am_box">
                      <img src={data.ImgIcon} />
                      <h6 className="tt-title">{data.title}</h6>
                      {data?.para?.slice(0, 70)}
                      {data?.para?.length > 70 ? "..." : ""}
                    </div>
                    <div class="text-center">
                      <Link to={data.BtnUrl} class="abt-heading">
                        Learn More
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WhyBuy;
