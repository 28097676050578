import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/LondonFooter.css";
function LondonFooter() {
  return (
    <>
      <div className="footer2">
        <div className="main-footer2">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="usefull-links">
                  <ul>
                    <li>
                      <Link to="/diamonds">Diamonds </Link>
                    </li>
                    {/* <li>
                       <Link to="/gemstones">
                        Gemstones
                       </Link>
                    </li> */}
                    <li>
                      <Link to="/ringsettings">Engagement Rings</Link>
                    </li>
                    <li>
                      <Link to="/jewelry/wedding-rings">Wedding Rings</Link>
                    </li>
                    <li>
                      <Link to="/jewelry/fine-jewelry">Fine jewelry</Link>
                    </li>
                    <li>
                       <Link to="/education">
                        Education
                       </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us </Link>
                    </li>
                  </ul>
                </div>
                <div className="usefull-links mb_25">
                  <ul>
                    {/* <li>
                       <Link to="#">
                        Cookies Policy
                       </Link>
                    </li> */}
                    <li>
                      <Link to="/terms-and-conditions">Terms Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="get-itouch">
                  <ul>
                    <li>
                      {/*<i class="fa fa-volume-control-phone"></i>*/}
                      <Link 
                      target="_blank"
                      onClick={() => {
                        window.open(
                          "tel:5617379595"
                        );
                      }}
                      to="#">561-737-9595</Link>
                    </li>
                    <li>
                      {/*<i class="fa fa-envelope dark"></i>*/}
                      <Link
                      target="_blank"
                      onClick={() => {
                        window.open(
                          "mailto:info@brillantejewelers.com"
                        );
                      }}
                       to="#">
                       info@brillantejewelers.com
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        onClick={() => {
                          window.open(
                            "https://www.google.com/maps/place//data=!4m2!3m1!1s0x88d8d8acf775940b:0x4bf445f5b8085398?source=g.page.share"
                          );
                        }}
                        to="#"
                      >
                        {/* <i class="fa fa-map-marker dark"></i>*/}
                        <span>
                          2232 North Congress Ave. Boynton Beach Fl 33426
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="block-footer2 text-center">
                  <div className="social-network-footer ">
                    <Link
                      to="#"
                      onClick={() => {
                        window.open(
                          "https://www.facebook.com/Brillantejewelers/"
                        );
                      }}
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </Link>

                    <Link
                      to="#"
                      className="inline-block round"
                      target="_blank"
                      onClick={() => {
                        window.open("https://twitter.com/BrillanteJewel");
                      }}
                    >
                      <i className="fa fa-twitter" />
                    </Link>

                    <Link
                      to="#"
                      className="inline-block round"
                      target="_blank"
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/Brillantejewelers/"
                        );
                      }}
                    >
                      <i className="fa fa-instagram" />
                    </Link>

                    {/* <Link to="#"
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-pinterest" />
                     </Link>  */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-txt">
                  <p>
                    <span>
                      Brillante Jewelers © 2023 All Rights Reserved. Designed
                      and Developed by: 
                    </span>
                    <Link to="http://belgiumwebnet.com/" target="_blank">
                       &nbsp; Belgium Webnet
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* desktop view ends*/}
        </div>
      </div>
    </>
  );
}

export default LondonFooter;
