import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Solitaire from '../../Assets/images/engagement/solitaire_ring.webp';
import Pave from '../../Assets/images/engagement/pave_ring.webp';
import Halo from '../../Assets/images/engagement/halo_ring.webp';
import Vintage from '../../Assets/images/engagement/vintage_ring.webp';
import ThreeStone from '../../Assets/images/engagement/three_stone_ring.webp';
import ChannelSet from '../../Assets/images/engagement/single_row_ring.webp';
import Multirow from '../../Assets/images/engagement/multirow_ring.webp';
import Bypass from '../../Assets/images/engagement/bypass_ring.webp';
import SideStone from '../../Assets/images/engagement/side_stone_ring.webp';
import other from '../../Assets/images/engagement/trellis_ring.webp';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const RingStyle = () => {
    var history = useHistory();

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settingsV2 = {
        dots: false,
        arrows: true,
        centerMode: true,
        // infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
        history.push('/ringsettings/diamonds');
    }
    return (
        <>
            <Container className='rcs_custom_home_container mt-3' >
                <div className='rcs_ringstyle rcs_ringstyle_v2' >
                    <Row>
                        <Col lg={12} xs={12}>
                            <div className='rcs_ringstyle_title'>
                                <h2> SHOP BY STYLE </h2>
                            </div>
                        </Col>
                    </Row>

                    <Slider {...settingsV2}>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={Solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')}/>
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={Pave} alt='Pave'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={Halo} alt='Halo'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={ThreeStone} alt='Three Stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Channel Set')} src={ChannelSet} alt='Channel Set'/>

                            </div>
                            <h2 onClick={() => shopbystyle('Channel Set')}>Channel Set</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Multirow')} src={Multirow} alt='Multirow'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Multirow')}>Multirow</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Bypass')} src={Bypass} alt='Bypass'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Bypass')}>Bypass</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Side stone')} src={SideStone} alt='Side-stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Side stone')}>Side Stone</h2>
                        </div>

                    </Slider>

                </div>


            </Container>
        </>
    );
}

export default RingStyle;