import { Button } from "@mui/material";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ringSetting from "../../Assets/images/home/ring-setting.webp";
import settingDiamonds from "../../Assets/images/home/setting-diamonds.webp";
import finalRing from "../../Assets/images/home/final-ring.webp";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Ringbuilderhome = () => {
  const history = useHistory();
  return (
    <>
      <div className="rcs_ringbuilder_home">
        <Container className="rcs_custom_home_container mt-3">
          <Row className="w-100 m-auto">
            <Col>
              <div
                className="rcs_ringbuilder_top_head mb-5 mb-md-2"
                data-aos="fade-up"
              >
                <h2>Create your own</h2>
                <p> Diamond ring </p>
              </div>
            </Col>
          </Row>
          <Row className="w-100 m-auto justify-content-center">
            {/* <Col xs={12} sm={12} md={1}></Col> */}
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="rcs_diamonds_ring">
                <Row className="w-100 align-items-center justify-content-center mt-5 mt-md-4 mr-0 ml-0">
                  <Col xs={12} sm={12} md={5} className="p-0">
                    <div
                      className="rcs_diamonds_ring_content text-center"
                      data-aos="fade-up"
                      data-aos-offset="300"
                      data-aos-duration="700"
                      data-aos-easing="ease-in-sine"
                    >
                      <h2>Step 1</h2>
                      <p>Choose a setting</p>
                      <LazyLoadImage
                        src={ringSetting}
                        onClick={() => history.push("/ringsettings")}
                        alt="ring setting"
                      />
                      <Button
                        variant="contained"
                        onClick={() => history.push("/ringsettings")}
                        className="rcs_ringbuilder_button br-0"
                      >
                        start with a setting
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={2}>
                    <div
                      className="rcs_diamonds_ring_add_content text-center"
                      data-aos="fade-up"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="900"
                    >
                      <h3>+</h3>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={5} className="p-0">
                    <div
                      className="rcs_diamonds_ring_content text-center"
                      data-aos="fade-up"
                      data-aos-offset="300"
                      data-aos-duration="1100"
                      data-aos-easing="ease-in-sine"
                    >
                      <h2>Step 2</h2>
                      <p>choose a diamond</p>
                      <LazyLoadImage
                        src={settingDiamonds}
                        onClick={() => history.push("/diamonds")}
                        alt="diamond"
                      />
                      <Button
                        variant="contained"
                        onClick={() => history.push("/diamonds")}
                        className="rcs_ringbuilder_button"
                      >
                        start with a diamond
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={2} xl={1}>
              <div
                className="rcs_diamonds_ring_equal d-flex align-items-center justify-content-center position-relative h-100"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1400"
              >
                <h3>=</h3>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <div
                className="rcs_diamonds_ring_final"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1400"
              >
                <LazyLoadImage src={finalRing} alt="diamond" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Ringbuilderhome;
