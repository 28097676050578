import React, { useEffect, useState } from 'react';

import { Col, Container, Image, Nav, Row, Form, Dropdown, Modal, Accordion } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import '../assets/css/LondonHeader.css';
import logoImage from '../../../../Assets/images/brillante-logo.webp';
import SearchIcon from '@mui/icons-material/Search';
import Login from '../../../../Components/Login';
import { useHistory } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import menuImages from "../../../../Assets/images/menu.png";
import { img_url, isLogin } from '../../../../Helpers/request';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import "../../../../../node_modules/react-slidedown/lib/slidedown.css";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BiHeart } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import Heart1 from "../../../../Assets/images/heart1.png";
import Heart2 from "../../../../Assets/images/heart2.gif";
import Cart1 from "../../../../Assets/images/bag1.png";
import Cart2 from "../../../../Assets/images/bag2.gif";
import Magnify0 from "../../../../Assets/images/search1.png";
import Magnify1 from "../../../../Assets/images/magnify2.gif";
import { useSelector } from 'react-redux';
import { BsFillChatFill, BsFillTelephoneFill } from 'react-icons/bs';
import { IoMdMail, IoMdContact } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const LondonHeader = ({ menudata, currencydata }) => {
    const [submenu, setSubmenu] = useState([]);
    const [show, setShow] = useState(false);
    const history = useHistory();
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
    const [dropdown, setDropdown] = useState({
        img: localStorage.getItem("bw-currency_img"),
        icon: localStorage.getItem("bw-currencyicon"),
        code: localStorage.getItem("bw-currency")
    });
    const [topHeader, setTopHeader] = useState(true);
    const [left, setLeft] = useState(false);
    const [hover, setHover] = useState(false);
    const [hoverindex, setHoverindex] = useState(false);
    const [search_text, setSearch_text] = useState('');
    const [search_show, setSearch_show] = useState(false);
    const [wishover, setWishover] = useState(false);
    const [carthover, setCarthover] = useState(false);
    const [searchhover, setSearchhover] = useState(false);
    const cartlength = useSelector(state => state.persistedReducer.cart.cartlength)
    const wishlistlength = useSelector(state => state.persistedReducer.wish.whishlistlength)
    const classes = useStyles();
    useEffect(() => {

        const handleScroll = () => {
            setIsScrollValueMoreThanHeaderHeight(window.scrollY > 15);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    const toggleDrawer = (open) => {
        setLeft(open);
    };


    const currencyHandle = async (code, img, icon) => {
        var session_id = localStorage.getItem('bw-session-id') ? localStorage.getItem('bw-session-id') : ""
        setDropdown({ img: img, icon: icon, code: code });
        localStorage.removeItem('bw-fancycolordata');
        localStorage.removeItem('bw-gemstonedata');
        localStorage.removeItem('bw-diamonddata');
        localStorage.removeItem('bw-settingdata');
        localStorage.setItem("bw-currency", code);
        localStorage.setItem("bw-currency_img", img);
        localStorage.setItem("bw-currencyicon", icon);
        sessionStorage.removeItem("bw-listfilter");
        sessionStorage.removeItem("bw_f_filter");
        sessionStorage.removeItem("bw_s_filter");
        sessionStorage.removeItem("bw_d_filter");
        sessionStorage.removeItem("bw_g_filter");
        sessionStorage.removeItem("bw-list-category");
        if (window.location.pathname == "/complete-diamond-ring") {
            history.push("/diamonds")
        } else if (window.location.pathname == "/complete-gemstone-ring") {
            history.push("/gemstones")
        } else if (window.location.pathname == "/complete-fancycolor-ring") {
            history.push("/fancy-color-diamond")
        }
        window.location.reload();
    }

    console.log(submenu)

    return (
        <>
            <div className="rcs_header_seaction" id="rcs_header_seaction">
                {/* <div className="rcs_header_seaction" id="rcs_header_seaction"> */}
                {/* Top Header Section */}
                {topHeader ?
                    <div className="rcs_top_header">
                        <Container className='rcs_custom_home_container'>
                            <div className="rcs_top_head_content">
                                <Row className="w-100 m-auto align-items-center">
                                    <Col xs={12} md={9} xl={4} lg={12} className='p-0'>
                                        <div className='rcs_top_head_content_right'>
                                            <ul>
                                                <li onClick={(e) => { window.location.href = `tel:5617379595` }}> <span> 561-737-9595 </span>  </li>
                                                <li onClick={(e) => { window.location.href = `/make-an-appointment` }} className="header_inquiry_text"> MAKE AN ENQUIRY </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    {isMobile ? '' : <Col xs={12} md={6} lg={4} className='p-0'>
                                                    <div className='text-center d-none d-xl-block'>
                                                    <Image className='brillante_logo_img' style={{cursor: "pointer"}} onClick={() => history.push('/')} src={logoImage} alt="Brillante Jewelers"></Image>
                                                    </div>
                                    </Col>}
                                    <Col xs={12} md={3} lg={4} className='p-0'>
                                    <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                                        <ul>
                                            {/* <li className="d-none d-lg-block" onClickAway={() => setSearch_show(false)}>
                                                <img onClick={() => setSearch_show(!search_show)} onMouseOver={() => setSearchhover(true)} onMouseLeave={() => setSearchhover(false)} className='rcs_wishlist_icon' src={searchhover ? Magnify1 : Magnify0} />
                                                <Modal className={isScrollValueMoreThanHeaderHeight ? "rcs_search_modal12" : "rcs_search_modal"} show={search_show} onHide={() => setSearch_show(false)}>
                                                    <div className="rcs_search_bar">
                                                        <Form onSubmit={(e) => { e.preventDefault(); history.push('/search/' + search_text); window.scrollTo(0, 0); setSearch_text(""); setSearch_show(false); }}>
                                                            <SearchIcon onClick={(e) => { history.push('/search/' + search_text); setSearch_text(""); setSearch_show(false); }} />
                                                            <Form.Control type="text"
                                                                placeholder="Search for.."
                                                                value={search_text}
                                                                onChange={(e) => setSearch_text(e.target.value)}
                                                            />
                                                            <AiOutlineClose onClick={() => setSearch_show(!search_show)} />

                                                        </Form>
                                                    </div>
                                                </Modal>
                                            </li> */}
                                            <li className="d-none d-lg-block pointer" ><Login /></li>
                                            <li className="d-none d-lg-block pointer" onClick={() => isLogin ? history.push('/account/wishlist') : history.push('/wishlist')}> <img onMouseOver={() => setWishover(true)} onMouseLeave={() => setWishover(false)} className='rcs_wishlist_icon' src={wishover ? Heart2 : Heart1} /> <span> Wishlist </span> ({wishlistlength && wishlistlength != '0' ? <span >{wishlistlength}</span> : '0'}) </li>
                                            <li className="d-none d-lg-block pointer" onClick={() => { history.push('/cart'); }}><img onMouseOver={() => setCarthover(true)} onMouseLeave={() => setCarthover(false)} className='rcs_wishlist_icon' src={carthover ? Cart2 : Cart1} /> <span> My Cart </span>  ({cartlength && cartlength != '0' ? <span className="">{cartlength}</span> : '0'}) </li>
                                         
                                        </ul>
                                    </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div> : ""}
                {/* Top Header Section */}
                <div className={isScrollValueMoreThanHeaderHeight ? "rcs_logo_head rcs_sticky-top sticky-top" : "rcs_logo_head"}>
                    {/* Header Section */}
                    <div className="rcs_header">
                        <Container className="rcs_custom_home_container p-0">
                            <Row className="w-100 m-auto">
                                <Col sm={12} md={12} lg={2} xl={2} xs={12} className="rcs_large_Tab d-xl-none ">
                                    <Nav className="m-auto rcs_mobile_header_menu">
                                        <Image  onClick={() => toggleDrawer(true)} src={menuImages} alt="menu icon" width="30px"></Image>
                                    </Nav>
                                    <div className="rcs_logo_section">
                                        {/* <NavLink to="/"> */}
                                        <Image style={{cursor:"pointer"}} onClick={() => history.push('/')} src={logoImage} alt="Brillante Jewelers"></Image>
                                        {/* </NavLink> */}
                                    </div>
                                    <div className="rcs_right_side rcs_right_side_mobile1 d-lg-none">
                                        <ul>
                                            <li><BiSearch onClick={() => setSearch_show(!search_show)} /></li>
                                            <li><Login /></li> 
                                            <li onClick={() => isLogin ? history.push('/account/wishlist') : history.push('/wishlist')}><BiHeart className='rcs_cart_mobile_icon1' />   {wishlistlength && wishlistlength != '0' ? <span className="rcs_wish_badge">{wishlistlength}</span> : ''} </li>
                                            <li onClick={() => { history.push('/cart'); }}><MdOutlineShoppingBag className='rcs_cart_mobile_icon' /> {cartlength && cartlength != '0' ? <span className="rcs_cart_badge">{cartlength}</span> : ''} </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col sm={12} md={12} lg={12} xs={12} className="rcs_large_Tab1">
                                    {/* Menu Section */}
                                    <div className="rcs_menu">
                                        <Container className='rcs_custome_container'>
                                            <Row>
                                                <nav id="rcs_menu_header_top">
                                                    <ul className="rcs_header_menu">
                                                        <li className='fixed_header_logo'> <div>   <Image style={{cursor:"pointer"}} onClick={() => history.push('/')} src={logoImage} alt="Brillante Jewelers"></Image> </div> </li>
                                                        {menudata?.map((value, index) =>
                                                            <li className='rcs_top_header_menu'
                                                                onMouseEnter={() => { setHover(1); setSubmenu(value) }}
                                                                onMouseLeave={() => setHover(0)} >
                                                                <h6 ><NavLink to={value?.slug}>{value?.name} </NavLink></h6>
                                                            </li>
                                                        )}
                                                        <li>
                                                        <div className="rcs_search_bar">
                                                        <Form onSubmit={(e) => { e.preventDefault(); history.push('/search/' + search_text); window.scrollTo(0, 0); setSearch_text(""); setSearch_show(false); }}>
                                                            <Form.Control type="text"
                                                                placeholder="Search"
                                                                value={search_text}
                                                                onChange={(e) => setSearch_text(e.target.value)}
                                                            />
                                                             <SearchIcon onClick={(e) => { history.push('/search/' + search_text); setSearch_text(""); setSearch_show(false); }} />
                                                         
                                                        </Form>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                    
                                                </nav>
                                            </Row>
                                        </Container>
                                    </div>
                                    {/* menu Section */}
                                </Col>
                                <Col sm={12} md={12} lg={2} xs={12} className="rcs_large_Tab1 d-none">
                                    <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                                        <ul>
                                            <li className="d-none d-lg-block" onClickAway={() => setSearch_show(false)}>
                                                <img onClick={() => setSearch_show(!search_show)} onMouseOver={() => setSearchhover(true)} onMouseLeave={() => setSearchhover(false)} className='rcs_wishlist_icon' src={searchhover ? Magnify1 : Magnify0} />
                                                <Modal className={isScrollValueMoreThanHeaderHeight ? "rcs_search_modal12" : "rcs_search_modal"} show={search_show} onHide={() => setSearch_show(false)}>
                                                    <div className="rcs_search_bar">
                                                        <Form onSubmit={(e) => { e.preventDefault(); history.push('/search/' + search_text); window.scrollTo(0, 0); setSearch_text(""); setSearch_show(false); }}>
                                                            <SearchIcon onClick={(e) => { history.push('/search/' + search_text); setSearch_text(""); setSearch_show(false); }} />
                                                            <Form.Control type="text"
                                                                placeholder="Search for.."
                                                                value={search_text}
                                                                onChange={(e) => setSearch_text(e.target.value)}
                                                            />
                                                            <AiOutlineClose onClick={() => setSearch_show(!search_show)} />

                                                        </Form>
                                                    </div>
                                                </Modal>
                                            </li>
                                            <li className="d-none d-lg-block" ><Login /></li>
                                            <li className="d-none d-lg-block" onClick={() => isLogin ? history.push('/account/wishlist') : history.push('/wishlist')}> <img onMouseOver={() => setWishover(true)} onMouseLeave={() => setWishover(false)} className='rcs_wishlist_icon' src={wishover ? Heart2 : Heart1} /> {wishlistlength && wishlistlength != '0' ? <span className="rcs_wish_badge">{wishlistlength}</span> : ''} </li>
                                            <li className="d-none d-lg-block" onClick={() => { history.push('/cart'); }}><img onMouseOver={() => setCarthover(true)} onMouseLeave={() => setCarthover(false)} className='rcs_wishlist_icon' src={carthover ? Cart2 : Cart1} />  {cartlength && cartlength != '0' ? <span className="rcs_cart_badge">{cartlength}</span> : ''} </li>
                                         
                                        </ul>
                                    </div>

                                    
                                </Col>
                            </Row>
                        </Container>
                        
                              {/*mega menu */}
  <div
                    onMouseEnter={() => setHover(1)}
                    onMouseLeave={() => setHover(0)}
                    className={
                      hover == "1" && submenu?.submenu?.length
                        ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" + submenu?.name?.replaceAll(" ","-")
                        : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                    }
                  >
                    <div className="rcs_megaMenu d-block">
                      <div className="rcs_megaMenu_inner_top">
                        <div className="rcs_megaMenu_inner">
                          <Container fluid className="p-0">
                            <Row className="w-100 m-auto  justify-content-center">
                              {submenu?.submenu?.map((val, index) => <>
                                <Col lg={val?.col} xl={val?.col} className="pl-0">
                                  {val?.submenu?.map(val1 =>
                                    val1?.design == "1" ?
                                      <div className={"rcs_diamonds_content mj_daimond_d_font bw-" + val1?.name?.replaceAll(" ","-")}>
                                        <div className="mega-list-cat">
                                          <h2 className="title14 font-bold text-uppercase mtb">
                                            {val1?.name}
                                          </h2>
                                          <ul>
                                            {val1?.submenu?.map(val2 =>
                                               <li>
                                               {val2?.design == 4 ? (
                                                 <Link to="#" onClick={()=> window.open(img_url + val2?.slug,"_target")}>
                                                   {val2?.img_url?.length ? (
                                                     <img
                                                       src={val2?.img_url}
                                                       className="img-size"
                                                       alt={val2?.alt_tag}
                                                     />
                                                   ) : (
                                                     ""
                                                   )}
                                                   {val2?.name}
                                                 </Link>
                                               ) : (
                                                 <Link to={val2?.slug}>
                                                   {val2?.img_url?.length ? (
                                                     <img
                                                       src={val2?.img_url}
                                                       className="img-size"
                                                       alt={val2?.alt_tag}
                                                     />
                                                   ) : (
                                                     ""
                                                   )}
                                                   {val2?.name}
                                                 </Link>
                                               )}
                                             </li>
                                            )}
                                          </ul>
                                        </div>

                                      </div>
                                      :
                                      val1?.design == "2" ?
                                        <div className={"rcs_diamonds_content dfL-border_rel bw-" + val1?.name?.replaceAll(" ","-")}>
                                          <h2> {val1?.name} </h2>
                                          <div className="m-auto w-100 row">
                                            {val1?.submenu?.map(val2 =>
                                              <div className="cust_padd_diamonds_shape col-lg-6">
                                                <div className="rcs_diamonds_content_inner">
                                                  <Link to={val2?.slug}>
                                                  {val2?.img_url?.length ?
                                                   <img
                                                      src={val2?.img_url}
                                                      alt={val2?.alt_tag}
                                                    />:""}
                                                    <p>{val2?.name}</p>
                                                  </Link>
                                                </div>
                                              </div>
                                            )}
                                          </div>

                                        </div>
                                        :
                                        val1?.design == "3" ?
                                          <div className="rcs_diamonds_content">
                                            <Link to={val1?.slug}>
                                            {val1?.img_url?.length ?
                                            <img
                                                src={val1?.img_url}
                                                alt={val1?.alt_tag}
                                                className="img-fluid dfl_ddiamond_img"
                                                style={{
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }}
                                              />:""}
                                            </Link>
                                          </div>
                                          :
                                          ""
                                  )}
                                  {index < 2 ? <div className="dfl_menu_border"> </div> : ""}
                                </Col>
                              </>
                              )}
                            </Row>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* menu Section */}
                  
                    </div>
              
                    {/* Header Section */}

                    {hover == "1" && submenu?.submenu?.length ? <div className='hover_overlayer'></div> : "" }
                    
                </div>



                {/* Mobile Menu Section */}
                <div className="rcs_menu_mobile d-none">
                    <Container>
                        <Row>
                            <Drawer anchor="left" open={left} onClose={() => toggleDrawer(false)}>
                            <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    <div className="inner-mobile-menu">
                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              <Link
                                to={val.slug}
                                onClick={() => setShow(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <img
                                      className="w-100 mt-2"
                                      onClick={() => history.push(val2?.slug)}
                                      src={val2?.img_url}
                                      alt={val2?.alt_tag}
                                    />
                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() => setShow(false)}
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                              
                              <li>
                                <a
                                 target="_blank"
                                 onClick={() => {
                                   window.open(
                                     "tel:561-737-9595"
                                   );
                                 }}
                                 
                                  href="#"
                                >
                                  <span>
                                    <BsFillTelephoneFill />
                                  </span>{" "}
                                  Call
                                </a>
                              </li>
                              <li>
                                <a
                                target="_blank"
                                onClick={() => {
                                  window.open(
                                    "mailto:brillantejewelers@gmail.com"
                                  );
                                }}
    
                                  href="#"
                                >
                                  <span>
                                    <IoMdMail />
                                  </span>{" "}
                                  Email
                                </a>
                              </li>
                              <li>
                                <Link
                                  onClick={() => setShow(false)}
                                  to="/contact"
                                >
                                  <span>
                                    <IoMdContact />
                                  </span>{" "}
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
                            </Drawer>
                        </Row>
                    </Container>
                </div>
                {/* Mobile Menu Section */}
                
            </div>
           
        </>
    )
}
export default LondonHeader;