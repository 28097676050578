import React from "react";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";

function VideoBanner() {
  const history = useHistory();

  return (
    <>
      <div className="Lt_videoBanner_main">
        <ReactPlayer
          url="https://dl2vs6wk2ewna.cloudfront.net/scrap/spruce-video.mp4"
          width="100%"
          height="100%"
          playing
          loop
          muted
          playsinline
        />

        <div className="Lt_video_banner_content">
          <h2>Custom Design</h2>
          <p>Our dedicated artisans include diamond setters, jewelry and polishers who work together to guarantee high standards in craftsmanship.</p>
          <Button className="london_banner_btn_outline" variant="outline-dark" target="_blank"
                      onClick={() => { history.push("/custom-design") }}> Learn More </Button>
        </div>
      </div>
    </>
  );
}

export default VideoBanner;
