import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import Home from './Containers/Front/Home';
import React from 'react';
import { Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { base_url, isLogin, postHeader } from './Helpers/request';
import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import MegaMenu from './Containers/Header/MegaMenu';
import { persistor, store } from './Store/store';
import { isDesktop } from 'react-device-detect';
import LondonHome from './Containers/Front/london/home/LondonHome';
import LondonHeader from './Containers/Front/london/header/LondonHeader';
import LondonFooter from './Containers/Front/london/footer/LondonFooter';
const Mainrouter = React.lazy(() => import('./mainrouter'));
const GotoTop = React.lazy(() => import('./Containers/Pages/Static/GotoTop'));



const App = () => {
  const [currencydata, setCurrencydata] = useState([]);
  const [menudata, setMenudata] = useState([]);

  useLayoutEffect(async() => {
    await clearlocal();
    if (localStorage.getItem("bw-session-id")?.length || isLogin) {
      console.log("session exist!")
    } else {
      axios.get(base_url + '/home/getsessionid')
        .then(res => {
          if (res.data.status == 1) {
            localStorage.setItem("bw-session-id", res.data.data?.session_id)
          } else {
            // toast.error(res.message,{autoClose: 3000});
            console.log("sessionid not generated")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    //-----------------------------------menu
    const device_type = isDesktop ? 1 : 2 ;
    axios.get(base_url + '/home/megamenu?device_type=' + device_type, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setMenudata(response.data.data);
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //-----------------------------------Banner
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // currency api
    axios.get(base_url + '/common/currency')
      .then(res => {
        if (res.data.status == 1) {
          setCurrencydata(res.data.data);
          localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : localStorage.setItem("bw-currency", res.data.data[0]?.currency_code);
          localStorage.getItem("bw-currency_img") ? localStorage.getItem("bw-currency_img") : localStorage.setItem("bw-currency_img", res.data.data[0]?.country_flag);
          localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : localStorage.setItem("bw-currencyicon", res.data.data[0]?.currency_icon);

        } else {
          console.log(res.data.message)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, []);
  const clearlocal = async () => {
    var localdate = localStorage.getItem('bw-localdate');
    var date = new Date().getDate();
    if (localdate != date) {
      await persistor.flush();
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("bw-localdate", date);
      window.location.reload()
    }
  }
  
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<>
        <Skeltonheader />
        
        </>}>
          {/* <MegaMenu menudata={menudata} currencydata={currencydata} /> */}
          <LondonHeader menudata={menudata} currencydata={currencydata} />
        </Suspense>
        <Switch>
        <Route exact path="/" component={LondonHome} />
          <Suspense fallback={<><Skelton /></>}>
            <Route path='*' exact={true} component={Mainrouter} />
          </Suspense>
        </Switch>

        <Suspense fallback={<><Skelton /></>}>
          <LondonFooter />
          <GotoTop />
        </Suspense>
      </BrowserRouter>
    </>
  )
}
const Skelton = () => {
  return (<>
    <div className='sk' style={{ margin: "15px 0px" }}>
      <Skeleton variant="text" animation="wave" />
    </div>
  </>)
}
const Skeltonheader = () => {
  return (<>
    <div className='skh' style={{ margin: "0px 0px 10px 0px" }}>
      <Skeleton variant="text" animation="wave" height={75} />
    </div>
  </>)
}
export default App;