import React, { useEffect } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRightShort } from 'react-icons/bs';


const LondonBanner = () => {
  const history = useHistory();

  const banner = localStorage.getItem("bw-bannerdata")
    ? JSON.parse(localStorage.getItem("bw-bannerdata"))
    : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;
  return (
    <>
      {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider london_banner" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}

      <div
        className="london_banner"
      >
  
          <Container className="rcs_custom_home_container">
            <Row className="m-auto w-100">
              <Col xl={5} lg={7} md={9} className="ml-auto">
                <div className="london_banner_text">
                  <h2> CREATE YOUR OWN </h2>
                  <h1>
                    Diamond Ring
                  </h1>
                  <p>
                    Select a setting and choose a diamond to create your own diamond engagement ring. <Link to="#"> Need help </Link>? We're always here
                  </p>

                 <div className="london_banner_btn_main">
                 <Button className="london_banner_btn_fill" onClick={(e) => { window.location.href = `/diamonds` }}> Start with a diamond <BsArrowRightShort/> </Button>
                  <Button className="london_banner_btn_outline" variant="outline-dark" onClick={(e) => { window.location.href = `/ringsettings` }} > Start with a setting <BsArrowRightShort/> </Button>
                 </div>
                </div>
              </Col>
            </Row>
          </Container>
    
      </div>
    </>
  );
};

export default LondonBanner;
