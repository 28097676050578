import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import Gimg from "../../Assets/images/home/google-img.webp";
import userImg from "../../Assets/images/home/slide-user-img.png";
import googleReview from "../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Customer = () => {
    const [customerData,setCustomerData] = React.useState([])
    React.useEffect(()=>{
        GetCustomerRewies();
        console.log('customerdta',customerData)
     },[])
     const GetCustomerRewies = () =>{
         axios.get(`${base_url}/common/site_reviews`,{
            headers: postHeader
        }).then(res=>{
            console.log(res)
            if(res.data.status == 1){
                setCustomerData(res.data)
            }
        }).catch(error=>{
            console.log(error)
        })
     }

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="rcs_customer_say_section">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_customer_say_title" data-aos="fade-up" data-aos-duration="1000">
                                <h2> What our customers say </h2>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_google_rating"  onClick={() => window.open(customerData?.google_review_url, "_blank")}>
                                <div className="rcs_customer_google_rating_img">
                                    <LazyLoadImage src={Gimg} alt="Google Image" />
                                </div>
                                <div className="rcs_customer_google_rating_star">
                                    <h4>{(customerData?.average_rating)?.toFixed(1)}</h4>
                                    <Stack spacing={1}>
                                        <Rating name="half-rating-read" defaultValue={5} precision={customerData?.average_rating} size="large" readOnly />
                                    </Stack>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_review_btn">
                                <Button className="rcs_fill_button rcs_btn_rd-0" onClick={() => window.open(customerData?.google_review_url, "_blank")}> Write a Review </Button>
                            </div>
                        </Col>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
                            <div className="rcs_customer_review_slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>
                                {customerData?.data?.map(customdata => 
                                   <div className="rcs_customer_review_slide_item">
                                        <div className="rcs_customer_slide_title_img">
                                            <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div>
                                            <div className="rcs_customer_review_slide_title">
                                                <h3>{customdata?.username}</h3>
                                                <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                                            </div>
                                        </div>
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                                {(customdata?.review)?.slice(0,150)}{ customdata?.review?.length > 150 ? '...' : ""}
                                            </p>
                                            <Link to="#" onClick={() => window.open(customerData?.google_review_url, "_blank")}>  Read More </Link>
                                            <LazyLoadImage src={googleReview} />
                                        </div>
                                    </div>)}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Customer;
