import React, { useEffect, useState } from 'react';
import LondonBanner from './LondonBanner';
import '../assets/css/LondonHome.css';
import EngWedRing from './EngWedRing';
import BestSeller from './BestSeller';
import WhyBuy from './WhyBuy';
import LearnDiamond from './LearnDiamond';
import WhatPeopleSay from './WhatPeopleSay';
import InstaLondon from './InstaLondon';
import NewsLetter from './NewsLetter';
import VideoBanner from './VideoBanner';
import Serving from './Serving';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import Brilliant from '../../../../Assets/images/brilliant-pop.png';
import { IoCloseOutline } from 'react-icons/io5';
import {
  base_url,
  currencycode,
  postHeader,
} from '../../../../Helpers/request';
import axios from 'axios';
import swal from 'sweetalert';

function LondonHome() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [newsletteremail, setNewsletteremail] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const hasPopupBeenShown = localStorage.getItem('hasPopupBeenShown');
    if (!hasPopupBeenShown) {
      setTimeout(() => {
        setShow(true);
      }, 3000);

      localStorage.setItem('hasPopupBeenShown', 'true');
    }
  }, []);

  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + '/common/subscribe_newsletter', data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail('');
          swal(res.data.message, '', 'success');
          return setShow(false);
        } else {
          setLoader(false);
          return swal(res.data.message, '', 'error');
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <>
      <LondonBanner />
      <BestSeller />
      <EngWedRing />
      <Serving />
      <WhyBuy />
      <VideoBanner />
      <LearnDiamond />
      <WhatPeopleSay />
      <InstaLondon />
      <NewsLetter />

      <Modal
        className="brilliante-popup"
        backdrop={false}
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="brilliante-popup-wrapper">
            <div className="close-btn" onClick={handleClose}>
              <IoCloseOutline />
            </div>
            <img src={Brilliant} alt="" />
            <div className="subscribe-form">
              <Form className="gs_news_form w-100" onSubmit={newsletter}>
                <InputGroup>
                  <FormControl
                    placeholder="Enter your email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type="email"
                    value={newsletteremail}
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <Button
                    variant="outline-secondary"
                    type="submit"
                    id="button-addon2"
                  >
                    Subscribe
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LondonHome;
