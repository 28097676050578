import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function InstaLondon() {
  return (
    <> 
      <section className="Lt_insta_section">
        <Container fluid>
          <Row className="m-0 w-100">
          <Col>
              <div className="Lt_title_text">
                <h2> Get a Tour of our Social Media </h2>
              </div>
            </Col>
            <Col lg={12}>
                <div className="Lt_insta_main">
                <iframe src="https://instagram.demobw.live/brillantejewelersboynton/" frameBorder={0} width="100%" className="insta-iframe" />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default InstaLondon;
